<template>
     <Subscription/>
</template>

<script>
// @ is an alias to /src
import Subscription from '@/components/login/Subscription.vue'

export default 
{

  components: 
  {
    Subscription
  }
}
</script>

<template>
  <main class="col-md-16 ms-sm-auto col-lg-16 px-md-4">
    <div class="row row-cols-1 row-cols-lg-8 align-items-stretch g-4 py-5">
      <h3 class="text-dark-blue">Subscription plans</h3>
      <span class="text-muted  pb-2 margin-top-subtitle"> Choose the plan that's right for your team</span>
      <span class="text-muted  pb-2 margin-top-subtitle"><h3>You will get charged after 30 day trial.</h3></span>
      <div class="container m-0">
        <div class="row row-cols-1 row-cols-md-3 mb-3 text-center" v-if="ListPlans.status != 'false'">
          <!-- Plan 1 -->
          <div class="col" v-for="(obj, key, index) in ListPlans.list" :key="index">
            <div class="card mb-4 rounded-3 shadow-sm">
              <div class="card-header py-3">
                <h4 class="my-0 fw-normal">{{obj.name}}</h4>
              </div>
              <div class="card-body">
                <div> <h1 class="card-title pricing-card-title">{{obj.amount}} $ </h1> <h4 class="text-muted fw-light">x User / Monthly</h4></div>
                <ul class="list-unstyled mt-3 mb-4">
                  <div v-if="obj.name == 'Basic'">
                    <li>Track work time</li>
                    <li>3 month of data</li>
                    <li>Manage payments</li>
                    <li>Unlimited projects</li>
                  </div>
                  <div v-if="obj.name == 'Pro'">
                    <li>URL analysis</li>
                    <li>Application analysis</li>
                    <li>6 month of data</li>
                    <li>All basic benefits included</li>
                  </div>
                  <!-- <li>{{obj.rules[0].value}} {{obj.rules[0].description}} </li> -->
                  <li><input type="text" v-model="coupon.coupon_id" class="form-control" id="email" placeholder="Enter promo code"> </li>
                </ul>
                <button type="button" v-on:click="CheckoutPLan(obj.name)" class="border-blue btn-plan-w text-dark-blue w-100 btn btn-md btn-outline-primary">Get started</button>
              </div>
            </div>
          </div> 
        </div>
      </div>
    </div>
  </main>
</template>

<script>
  import Api from '@/services/Api';

  export default 
  {
    data: () => (
    {
      ListPlans: 
      {
        status: "false",
        list: {}
      },
      coupon:
      {
        coupon_id : "",
      }
    }),
    methods: 
    {
      GetPLans()
      {
        Api.get('/stripe/plans').then((result) => 
        {
          this.ListPlans.status = result.data.status

          if (this.ListPlans.status == "true") 
          {
            this.ListPlans.list = result.data['plans']; 
          }
        })
      },
      CheckoutPLan(PlanName) 
      {
        let Post = 
        {
          plan:PlanName,
          code : this.$route.params.id,
          coupon_id: this.coupon.coupon_id
          
        }
        
        Api.post("/stripe/checkout",Post).then((result) => 
        {
          if(result.data.status == "false")
          {
            this.$moshaToast( ''+result.data.msg+'', { type: 'default', position:'bottom-right', transition:'zoom' })
          }

          if(result.data.status == "true")
          {
            this.$moshaToast(''+result.data.msg+'', { type: 'default', position:'bottom-right', transition:'zoom' })
            window.location.replace(result.data.url)
          }
        })
      },
    },
    mounted()
    {
      this.GetPLans(); 
    }
  }
</script>

<style scoped></style>
